<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    
    <!--end::Header-->
    <div class="row card-body pt-10 pb-3">
      <div class="col-md-8">
        <div class="row">
          <div class="col-12 mb-4">
            <span class="theme-card-title  font-poppins  ">Select theme to load configuration</span>
          </div>

          <div v-for="theme in filteredThemes" :key="theme.value" class="col-md-4 mb-4 mt-10">
            <div
              class="theme-card"
              :class="{ 'theme-card-selected': theme.value === themeId }"
              @click="selectTheme(theme)"
            >
              <img :src="theme.image" class="theme-card-image" />
              <div class="card-label font-weight-bolder text-dark font-poppins pt-1">{{ theme.text }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="row">
          <TemplateSettings v-if="theme" :template="theme" />
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>

import { GET_TEMPLATES, GET_CURRENT_TEMPLATE } from "@/core/services/store/profile.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Template1 from "@/view/pages/templates/Template1";
import TemplateSettings from "@/view/pages/business/components/settings/TemplateSettings";
import { mapGetters } from "vuex";

export default {
  name: "Setting",
  components: {
    Template1,
    TemplateSettings
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"]),
    filteredThemes() {
      return this.themes.filter(theme => theme.text !== null);
    }
  },
  data() {
    return {
      themes: [
        { text: null, value: "Select your theme" ,"image":""}
      ],
      theme: null,
     
      themeId: null // Track the selected theme ID

    };
  },
  methods: {
    selectTheme(theme) {
      this.themeId = theme.value;
      this.theme = theme.value; // Update theme object
    }
  },
  mounted() {
    this.theme = parseInt(this.currentUserPersonalInfo.template_id);
      this.themeId=this.theme 
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Themes" }
    ]);

    this.$store.dispatch(GET_TEMPLATES).then((result) => {
        result.forEach((v) => {
          this.themes.push({
            value: v.id,
            text: v.template_name,
            image:v.image
          });
        });
      });
  }
};
</script>
<style lang="scss">
.theme-card {
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 100px;
}

.theme-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.theme-card-selected {
  border-color: #4CAF50;
  background-color: #f0fdf4;
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.2);
}

.theme-card-image {
  max-width: 100%;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: transform 0.3s;
}

.theme-card:hover .theme-card-image {
  transform: scale(1.05);
}

.theme-card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}
</style>
